@font-face {
  font-family: 'titulos';
  src: url('./CCS/Font/RobotoSlab-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Titulos1';
  src: url('CCS/Font/BebasNeue-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;

}

.centrar{
  margin: 50px auto;
  float: none;
}

ol {
  list-style-type: none;
  color: white;
}

li {
  margin-top: 10px;
}


h1 {
  font-family: 'titulos';
}


h2 {
  font-family: 'Titulos1';
}


h3 {
  font-family: 'Titulos1';
}

/*-----------------INICIO--------------------------*/

button {
  font-family: 'Titulos1';
}

.contani1 {
  background-color: #1BA0F2;
  text-align: center;
}

.inicib {
  z-index: 5;
  position: absolute;
}

.videofondo{
width: 100%;
height: 450px;
object-fit: cover;
position: absolute;
filter: brightness(0.7);
z-index: 1;
}


.centrartexin{
  margin-left: 40px;
  margin-right: 40px;
}

.titulo1 {
  font-family: 'Titulos1';
  color: #F2A516;
  font-size: 90px;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 20px;
}


.titulo2 {
  font-family: 'Titulos1';
  color: #F2A516;
  font-size: 90px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.centrart {
  text-align: center;
}

.imginicio {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.imginicio2 {
  width: 400px;
  height: 400px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dialogo1 {
  width: 100%;
  background-color: aliceblue;
  height: 200px;
  border-radius: 10px;
}

.button1 {
  width: 100px;
  height: 50px;
  background-color: #1BA0F2;
  border-radius: 10px;
  font-size: 25px;
  color: white;
  margin-top: 10px;
}

.button1:hover{
  background-color: #2eafff;
  width: 120px;
  height: 70px;
  box-shadow: 10px 10px 5px 0px #2eafff;
  font-size: 40px;
  
}


.button2 {
  width: 100px;
  height: 50px;
  background-color: #F2A516;
  border-radius: 10px;
  font-size: 25px;
  color: black;
  margin-top: 10px;
}

/*-----------------CONOCIMIENTOS-------------------*/


.tituloconoprin{
  margin-left: 80px;
  margin-top: 80px;
  font-size: 80px;
  width: 100px;
  position: absolute;
}

.logosconoci {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  text-align: center;
  margin-top: 90px;
  margin-left: 40px;
  border-top: solid #F2A516 10px;
  border-bottom: solid #1ba0f2 10px;
  ;
}

.contenedorcolorcon {
  width: 100%;
  height: 900px;
  background-image: url('./CCS/img/promocionales/conocimiento1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.ciurculoblanco1con {
  width: 800px;
  height: 800px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  margin-top: -450px;
  margin-left: -200px;
  margin-bottom: 40px;
}


.tituloconocimientos {
  margin-top: 40px;
  font-size: 60px;
}

.titulocon1 {
  color: #F2A516;
  font-size: 60px;
  width: 100%;
  margin-top: 130px;
  margin-bottom: -40px;
  margin-left: 40px;
}

.adaprarlogocon {
  margin: 42px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.filacono {
  margin-top: 90px;
}

.concontran {
  background-color: #1b8ef28f;
  border-radius: 10px;
}

.margincon {
  margin-left: 20px;
}

.nombrecon {
  font-size: 40px;
  margin-top: 5px;
}

.contenedorconoati {
  width: 600px;
  height: 300px;
  margin-top: 60px;
}


.contenedorconoati img {
  width: 600px;
  height: 300px;
  object-fit: cover;
  filter: brightness(0.5);
}

.circuloconverini {
  position: absolute;
  border-radius: 100%;
  height: 600px;
  width: 600px;
  margin-top: -200px;
  margin-left: 63%;
  background-color: #1B8EF2;
}


/*--------------------TITULOS---------------*/


div:where(.swal2-container).swal2-center>.swal2-popup{
  width: 90%;
}

.swal2-image{
  width: 70%;
  height: 800px;
  object-fit: contain;
}

.alineartitulacion {
  display: flex;
}

.nadvarlateralti {
  width: 400px;
  height: 800px;
  background-color: #1B8EF2;
}

.nadvarlateralti2 {
  width: 1200px;
  height: 800px;
  display: flex;
}

.diplomasmotimg {
  width: 360px;
  height: 200px;
  margin-top: 150px;
  margin-left: 50px;
}

.diplomasmotimg img {
  width: 360px;
  height: 270px;
  object-fit: cover;
  border: solid #1BA0F2 5px;
  border-bottom-color: #F2A516;
  border-right-color: #F2A516;
  box-shadow: 2px 3px 6px 2px #F2A516;
}

.diplomasmotimg h3 {
  text-align: center;
  color: black;
  margin-top: 10px;
  font-size: 20px;
}

.bajartitulosdiplo {
  background-color: #1B8EF2;
  margin-top: 118px;
}


.titulosconoenci {
  margin-top: -96px;
  margin-left: 300px;
  position: absolute;
  font-size: 70px;
  color: white;
  text-decoration: none;
}


.titulosacademias {
  margin-Left: 20px;
  margin-Top: 40px;
  color: white;
}


/*---------------------PROYECTOS------------------*/

.menuproyectos {
  width: 100%;
  height: 400px;
  background-color: #1BA0F2;
}

.contenedorpro {
  width: 400px;
  height: 300px;
  background-color: silver;
  border-color: 10px;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
}

.contenedor-image-pro {
  width: 400px;
  height: 300px;
  position: absolute;
  object-fit: cover;
  filter: brightness(0.7);
  border-color: 10px;
}

.titulocontenpro {
  width: 350px;
  height: 50px;
  background-color: #F2A516;
  position: absolute;
  z-index: 5;
  text-align: center;
  margin-top: 10px;
  font-weight: bolder;
  font-size: 20px;
}

.tituproyectos {
  margin-top: 150px;
  text-align: center;
  font-size: 60px;
  z-index: 5;
  position: relative;
}


.conteneprotar {
  width: 100%;
  height: 200px;
  background-color: #1B8EF2;
  position: absolute;
}

/*------------NADVAR----------------*/


.logo_nav{
  width: 25px;
  margin-top: -10px;
}


.logonadvar{
  width: 100%;
  object-fit: contain;
  margin-top: 35px;
}
.nadvar-inic {
  width: 80%;
  height: 50px;
  background-color: #F2A516;
  position: absolute;
  z-index: 6;
  margin-left: 10%;
  margin-top: 40px;
  border-radius: 50px;
  border-color: white;
  box-shadow: 40px;
  border: solid #F2A516;
  text-align: center;
}

.cirvulo-nadvar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-color: white;
  margin: auto;
  margin-top: -50px;
  border: solid #F2A516 15px;
}


.ccontnad1 {
  width: 18%;
  height: 40px;
  margin-top: 10px;
  float: left;
  color: white;
}

.ccontnad2 {
  width: 41%;
  height: 40px;
  margin-top: 10px;
  float: left;
  color: white;
}

.contend-text-nadv {
  text-align: center;
  float: left;
  margin-left: 20%;
  font-weight: bolder;
  text-decoration: none;
}


.contend-text-nadv2 {
  text-align: center;
  float: left;
  margin-right: 20%;
  font-weight: bolder;
  
  text-decoration: none;
}


.contend-text-nadv a {
  color: white;
  text-decoration: none;
}


.contend-text-nadv2 a{
  color: white;
  text-decoration: none;
}

/*------------FOOTER-------------------*/

.footer {
  width: 100%;
  height: 60px;
  background-color: #1B8EF2;
}

/*------------LOGO SOBREPUESTO---------*/

.puntoconentn {
  width: 20px;
  height: 20px;
  background-color: #1BA0F2;
  border-radius: 100%;
  position: fixed;
  z-index: 7;
  display: none;
}

@keyframes ampliar {
  0% {
    width: 20px;
    height: 20px;
  }


  90% {
    margin-top: -300px;
    margin-left: -3000px;
    width: 8000px;
    height: 8000px;
  }

  100% {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    margin-left: 0px;
  }
}


/*-------------------CONTACTANOS---------------------------*/
.videofondocontact{
  width: 100%;
  height: 952.5px;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

.montarcontac{
  z-index: 5;
  position: absolute;
  margin-top: 150px;
  width: 100%;
  max-width: 1820px;
}

.contenmost1{
  height: 400px;
  background-color: white;
  border-radius: 10px;
}

.titulocontac1{
  color: #1BA0F2;
  text-align: center;
  font-weight: bolder;
  margin-top: 30px;
  font-size: 45px;
}

.contactext1{
  text-align: center;
  color: white;
}

.inptucontact{
  width: 50%;
  margin: 50px auto;
  float: none;
  border: solid #1B8EF2;
}

.iconoscontac{
  width: 80px;
  margin-top: 40px;
}
/*-------------RESPONSIVE----------------*/

@media (max-width: 1505px) {
  .contend-text-nadv{
    width: 10%;
  }
  .contend-text-nadv2{
    width: 20%;
  }
  .ccontnad2{
    margin-top: 2;
  }
  .nadvar-inic{
    height: 60px;
  }
}



@media (max-width: 1100px) {
  .ciurculoblanco1con {
    width: 400px;
    height: 400px;
    margin-top: 0px;
    margin-left: 0px;
    position: none;
    background-color: white;
  }
  .titulo1{
    font-size: 70px;
  }
}

@media (max-width: 900px) {
.nadvar-inic{
  height: 85px;
}
.contend-text-nadv {
  width: 50%;
}

.contend-text-nadv2 {
  width: 50%;
}

.cirvulo-nadvar{
  margin-left: -170px;
}
}